body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";

  scrollbar-color: #ccc #eee;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 11px;
  };

  &::-webkit-scrollbar-track  {
    background-color: #eee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}


input, textarea, select, button {
  font-family: 'Open Sans', sans-serif;
}

* {
  box-sizing: border-box;
}

pre {
  margin: 0;
}

.right{
  float: right;
}

.left {
  float: left;
}

ul, li {
  margin: 0;
  padding: 0;
}

body.marketing-mode{
  overflow: hidden;
}


a[disabled]{
  pointer-events: none;
}

.transparent-hide {
  opacity: 0;
}

button {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}