@import "src/constants";
@import "@netapp/bxp-design-system-react/styles/Forms/input.module";

$user-menu-header-height: 70px;

@mixin details-sec($height: 90px, $textColor: $grey-background) {
  height: $height;
  margin-top: 1px;
  padding: 20px 40px 20px 40px;
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    right: 40px;
    left: 40px;
    content: "";
    border-bottom: 1px solid var(--border);
  }

  // apply bottom line to last component
  &:last-child{
    &:after {
      position: absolute;
      bottom: 0;
      right: 40px;
      left: 40px;
      content: "";
      color: #e4e4e4;
      border-bottom: 1px solid var(--border);
    }
  }

  // hide upper line if first component
  &:nth-child(1){
    &:before {
      visibility: hidden;
    }
  }

  .button-wrapper {
    display: flex;
  }
}

.static-details {
  @include details-sec();

  .verification-svg, .pending-svg {
    width: 15px;
    height: 15px;
    fill: #30a998;
    margin-right: 10px;
  }

  .edit-svg {
    visibility:hidden;
    background-color: transparent;
    fill: var(--icon-tertiary);
    width: 17px;
    height: 17px;
  }

  .pending-svg {
    fill: $text-error;
  }

  &:hover {
    background-color: var(--hover-background);
    box-shadow: 1px 1px 2px 1px rgba(187, 187, 187, 0.5);
    cursor: pointer;
    top: 2px;
    z-index: 1;

    > div {
      position: relative;
      top: -2px;
    }

    .edit-svg {
      visibility: visible;
    }

    &.not-editable {
      cursor: context-menu;
    }
  }

  &:hover:before {
    visibility: hidden;
  }

  .info > svg {
    visibility: visible;
    width: 65px;
    height: 5px;
    fill: #6C6F78;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 345px;

    .text {
      display: flex;
      flex-direction: column;
      height: 55px;

      .info {
        color: var(--text-primary);
        font-size: 16px;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 305px;
        font-weight: 600;
      }

      .select-component {
        margin-top: 10px;
      }

      .info-name {
        color: var(--text-secondary);
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.info-error {
  color: $text-error !important;
  font-size: 15px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 380px;
}

.edit-name {
  @include details-sec(110px);
  padding: 20px 40px 29px 40px;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input-restrict {
      width: 65%;
    }

    input-container {
      margin-bottom: 0;
    }
  }
}

.edit-password, .email-sent {
  @include details-sec(161px);
  display: flex;
  flex-direction: column;
  padding: 23px 40px 23px 40px;


  .info-name {
    color: $greyish;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .info {
    color: $grey-6;
    font-size: 14px;
    margin-bottom: 8px;
    line-height: 1.71;
  }

  .button-wrapper {
    justify-content: flex-end;
  }
}

.email-sent {
  height: 108px;
}

.email-text {
  color: $grey-6;
  font-size: 15px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.action-button {
  border-top: 1px solid var(--border);
  margin-left: 40px;
  margin-right: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 90px;
}

.sliding-menu-bottom {
  bottom: auto !important;
  overflow-y: auto;
  max-height: 90vh;
}

.log-button {
  border: 1px solid var(--header-netapp-text-hover) !important;
  color: var(--text-button-primary) !important;
  font-weight: 600;
}

.user-details-container {
  width: 435px !important;
}

.user-details-header {
  height:52px !important;
  padding: 12px 40px !important;
}

.mask-class-name {
  opacity: 0 !important;
}

.resend-button {
  color: var(--text-button-primary) !important;
}